import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VContainer,{class:{ 'pt-5': !_vm.esCampus }},[(!_vm.escuelaTienePermiso)?_c(VSkeletonLoader,{attrs:{"type":"list-item-two-line@5"}}):_c(VCard,{attrs:{"rounded":"lg"}},[_c(VToolbar,{staticClass:"text-h6",attrs:{"dense":"","color":"secondary","dark":"","flat":""}},[_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.regresarAction()}}},[_c(VIcon,[_vm._v("mdi-arrow-left")])],1),_c(VSpacer),_vm._v(" "+_vm._s(_vm.examen ? _vm.examen.nombre : "")+" "),_c(VSpacer)],1),(_vm.loading)?_c(VCardText,[_c('loading-examen')],1):_c(VCardText,[_c(VContainer,[(_vm.idCurso)?_c(VAlert,{attrs:{"type":"info","text":"","dense":""}},[_vm._v("Esta es solo una previsualización del examen. Así se verá al momento de contestarlo.")]):_vm._e(),(_vm.tieneDescripcion)?_c('div',{staticClass:"ck-content mb-3",domProps:{"innerHTML":_vm._s(_vm.examen.descripcion)}}):_vm._e(),(!_vm.tieneSecciones)?_c(VAlert,{attrs:{"type":"warning"}},[_vm._v(" Este examen no tiene secciones para comenzar. ")]):_vm._e(),_c(VBtn,{attrs:{"color":"primary","small":"","disabled":!_vm.tieneSecciones},on:{"click":function($event){return _vm.comenzarExamen()}}},[_c('span',[_vm._v("Continuar")]),_c(VIcon,{staticClass:"ml2",attrs:{"small":""}},[_vm._v("mdi-chevron-right")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }