<template>
  <div>
    <v-container :class="{ 'pt-5': !esCampus }">
      <v-skeleton-loader
        v-if="!escuelaTienePermiso"
        type="list-item-two-line@5"
      ></v-skeleton-loader>
      <v-card v-else rounded="lg">
        <v-toolbar dense color="secondary" dark flat class="text-h6">
          <v-btn icon @click="regresarAction()">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-spacer></v-spacer> {{ examen ? examen.nombre : "" }}
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text v-if="loading">
          <loading-examen></loading-examen>
        </v-card-text>

        <v-card-text v-else>
          <v-container>
            <v-alert v-if="idCurso" type="info" text dense
              >Esta es solo una previsualización del examen. Así se verá al
              momento de contestarlo.</v-alert
            >
            <div
              v-if="tieneDescripcion"
              v-html="examen.descripcion"
              class="ck-content mb-3"
            ></div>
            <v-alert v-if="!tieneSecciones" type="warning">
              Este examen no tiene secciones para comenzar.
            </v-alert>
            <v-btn
              @click="comenzarExamen()"
              color="primary"
              small
              :disabled="!tieneSecciones"
            >
              <span>Continuar</span>
              <v-icon small class="ml2">mdi-chevron-right</v-icon>
            </v-btn>
          </v-container>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { cargarResultado } from "./examenAplicacionResultado.service";
import { cargarHistoricoExamen } from "../../../historicoExamen/historicoExamen.service";

import loadingExamen from "@/components/globals/template/examenes/loadingExamen.vue";
import { permisoMenuEscuela } from "../../../../helpers/utilerias";

export default {
  components: {
    "loading-examen": loadingExamen,
  },

  data: () => ({
    loading: true,
    examen: null,
    examenResultado: null,
    escuelaTienePermiso: false,
    aplicacionCurso: null,
  }),

  computed: {
    ...mapGetters(["app", "sessionToken", "menu"]),

    esCampus() {
      return this.app === "campus";
    },

    tipoCurso() {
      return this.$route.params.tipoCurso;
    },

    idAplicacion() {
      return this.$route.params.idAplicacion;
    },

    idExamen() {
      if (this.examen) return this.examen._id;
      return this.$route.params.idExamen;
    },

    idCurso() {
      return this.$route.params.idCurso;
    },

    idModulo() {
      return this.$route.params.idModulo;
    },

    idResultado() {
      return this.$route.params.idResultado;
    },

    tieneDescripcion() {
      if (this.examen) {
        const descripcion = this.examen.descripcion;
        return !!descripcion && descripcion !== "<p></p>";
      }
      return false;
    },

    tieneSecciones() {
      return this.examen && this.examen.secciones.length;
    },
  },

  watch: {
    sessionToken(value) {
      if (value) {
        if (!permisoMenuEscuela("Cursos")) this.$router.push("/dashboard");
        else {
          this.escuelaTienePermiso = true;
          this.cargarDatosExamen();
        }
      }
    },
  },

  mounted() {
    if (!permisoMenuEscuela("Cursos")) this.$router.push("/dashboard");
    else {
      this.escuelaTienePermiso = true;
      this.cargarDatosExamen();
    }
  },

  methods: {
    cargarDatosExamen() {
      if (this.sessionToken && this.idExamen) this.getExamen(this.idExamen);
      if (this.sessionToken && this.idResultado) this.getResultado();
    },

    async getResultado() {
      this.loading = true;

      try {
        const serverResponse = await cargarResultado(this.idResultado);
        this.loading = false;

        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
        } else {
          this.examen = serverResponse.examen;
          this.examenResultado = serverResponse.resultado;
          this.aplicacionCurso = serverResponse.aplicacionCurso;
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },

    async getExamen(idExamen) {
      this.loading = true;

      try {
        const serverResponse = await cargarHistoricoExamen({ idExamen });
        this.loading = false;

        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
        } else {
          this.examen = serverResponse.examen;
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },

    comenzarExamen() {
      const primeraSeccion = this.examen.secciones[0];

      this.$router.push({
        name: this.idCurso
          ? "SeccionAplicacionExamen"
          : "SeccionPresentarExamen",
        params: {
          idSeccion: primeraSeccion._id ? primeraSeccion._id : primeraSeccion,
          idExamen: this.idExamen,
          idCurso: this.idCurso,
          idModulo: this.idModulo,
          tipoCurso: this.tipoCurso,
          idResultado: this.idResultado,
        },
      });
    },

    regresarAction() {
      this.$router.push({
        name: "ExamenAplicacion",
        params: {
          idCurso: this.idCurso || this.aplicacionCurso.cursoCampus,
          idModulo: this.idModulo || this.aplicacionCurso.cursoModulo,
          tipoCurso: this.tipoCurso || "campus",
          idAplicacion: this.idAplicacion || this.aplicacionCurso._id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../classes/ck-content.css";
</style>
