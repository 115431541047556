import {
  getServerRequest
} from "../../services/serverRequest.service";
import { store } from "@/store/store";
const httpURL = store.getters.httpURL;

export const cargarHistoricoExamen = async ({ idExamen }) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/historicoExamenes/examen/${idExamen}`;
  return await getServerRequest(url, config);
};

export const cargarSeccionHistoricoExamen = async ({ idExamen, idSeccion }) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/historicoExamenes/examen/${idExamen}/seccion/${idSeccion}`;
  return await getServerRequest(url, config);
};

export const cargarPaginaHistoricoExamen = async ({ idExamen, idPagina }) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/historicoExamenes/examen/${idExamen}/pagina/${idPagina}`;
  return await getServerRequest(url, config);
};
