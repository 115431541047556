<template>
  <v-container>
    <v-skeleton-loader type="paragraph"></v-skeleton-loader>
    <v-skeleton-loader type="text"></v-skeleton-loader>
    <v-skeleton-loader
      v-for="index in 3"
      :key="index"
      type="list-item-avatar-two-line"
    ></v-skeleton-loader>
  </v-container>
</template>

<script>
export default {};
</script>
