import {
  getServerRequest,
  patchServerRequest,
} from "../../../../services/serverRequest.service";
import { store } from "@/store/store";
const httpURL = store.getters.httpURL;

export async function cargarResultado(idResultado) {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/examenResultado/${idResultado}`;
  return await getServerRequest(url, config);
}

export async function cargarSeccionResultado({
  idResultado,
  idExamen,
  idSeccion,
}) {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/examenResultado/${idResultado}/examen/${idExamen}/seccion/${idSeccion}`;
  return await getServerRequest(url, config);
}

export async function cargarPaginaResultado({
  idResultado,
  idExamen,
  idPagina,
}) {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/examenResultado/${idResultado}/examen/${idExamen}/pagina/${idPagina}`;
  return await getServerRequest(url, config);
}

export async function avanzarSeccionResultado({
  idResultado,
  idExamen,
  seccionActual,
}) {
  const body = { ...(seccionActual && { seccionActual }) };
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/examenResultado/${idResultado}/examen/${idExamen}/siguienteSeccion`;
  return await patchServerRequest(url, body, config);
}

export async function restarTiempoResultado({ idResultado, tiempo }) {
  const body = { ...tiempo };
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/examenResultado/${idResultado}/tiempo/restar`;
  return await patchServerRequest(url, body, config);
}
